
import { inject } from "vue";
import { sendEditInfo, getLicenseView, basicDelete } from "@/API/vl/vlOverview";
import { s3Download } from "@/utils/s3Download";
import moment, { Moment } from "moment";
import { message } from 'ant-design-vue';
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, ref } from "vue";
import ImagePreview from "@/views/RetailValidation/component/ImagePreview.vue";
import PdfPreview from "@/views/RetailValidation/component/PdfPreview.vue";

const carModelArray = ["小型车"];
const usageArray = [""];
export default defineComponent({
  props: {
    handleShow: {
      type: Function,
    },
    detailData: {
      type: Object,
      required: true,
    },
    canEdit: Boolean,
  },
  components: {
    ImagePreview,
    PdfPreview,
    DownloadOutlined,
    DeleteOutlined,
  },
  setup(props) {
    //获取entity,brand的值
    const injectObj: any = inject("provideObj");
    //行驶证图片
    const pictureModal = reactive({
      docType: "",
      pictureModalImg: "",
      pictureModalTitle: "",
      url: "",
      base64: ""
    });
    const exInfoShow = ref("private");
    const data = (props as any).detailData;
    const rules = {
      licenseNumber: [
        {
          required: true,
          validator: async (rule: any, value: string) => {
            if (/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
              return Promise.resolve();
            } else {
              if (value == "") {
                return Promise.reject("车牌号码为必填项");
              } else {
                return Promise.reject("请输入数字、汉字或字母");
              }
            }
          },
          trigger: "change",
        },
      ],
      customerName: [
        {
          required: true,
          validator: async (rule: any, value: string) => {
            if (value === "") {
              return Promise.reject("客户名称为必填项");
            } else {
              return Promise.resolve();
            }
          },
          trigger: "change",
        },
      ],
      vehicleType: [
        {
          required: true,
          validator: async (rule: any, value: string) => {
            if (/^[\u4e00-\u9fa5]+$/.test(value)) {
              return Promise.resolve();
            } else {
              if (value == "") {
                return Promise.reject("车辆类型为必填项");
              } else {
                return Promise.reject("请输入汉字");
              }
            }
          },
          trigger: "change",
        },
      ],
      engineNo: [
        {
          required: true,
          validator: async (rule: any, value: string) => {
            if (/^[a-zA-Z0-9]+$/.test(value)) {
              return Promise.resolve();
            } else {
              if (value == "") {
                return Promise.reject("发动机号码为必填项");
              } else {
                return Promise.reject("请输入字母或数字");
              }
            }
          },
          trigger: "change",
        },
      ],
      useNatue: [
        {
          required: true,
          validator: async (rule: any, value: string) => {
            if (/^[\u4e00-\u9fa5]+$/.test(value)) {
              return Promise.resolve();
            } else {
              if (value == "") {
                return Promise.reject("使用性质为必填项");
              } else {
                return Promise.reject("请输入汉字");
              }
            }
          },
          trigger: "change",
        },
      ],
      regDate: [
        {
          required: true,
          message: "注册日期为必填项",
          trigger: "change",
        },
      ],
      issueDate: [
        {
          required: true,
          message: "发证日期为必填项",
          trigger: "change",
        },
      ],
      contactPerson: [
        {
          required: true,
          message: "车辆联系人为必填项",
          trigger: "change",
        },
      ],
      registrationAddress: [
        {
          required: true,
          message: "车辆登记地址为必填项",
          trigger: "change",
        },
      ],
      purchaserAddress: [
        {
          required: true,
          message: "购车人居住地所在区县为必填项",
          trigger: "change",
        },
      ],
      phoneNo: [
        {
          required: true,
          message: "手机号码（座机电话）为必填项",
          trigger: "change",
        },
      ],
      vehicleLicenseFileNo: [
        {
          required: true,
          message: "档案编号为必填项",
          trigger: "change",
        },
      ],
      companyAddress: [
        {
          required: true,
          message: "公司地址为必填项",
          trigger: "change",
        },
      ],
      chargingPoleLocation: [
        {
          required: true,
          message: "对应车辆充电桩地址为必填项",
          trigger: "change",
        },
      ],
      storageLocation: [
        {
          required: true,
          message: "存放地点为必填项",
          trigger: "change",
        },
      ],
      representativeName: [
        {
          required: true,
          message: "运营单位法人代表姓名为必填项",
          trigger: "change",
        },
      ],
      representativePhone: [
        {
          required: true,
          message: "法人代表手机为必填项",
          trigger: "change",
        },
      ],
      operationCompanyAddress: [
        {
          required: true,
          message: "运营单位地址为必填项",
          trigger: "change",
        },
      ],
      operationAddress: [
        {
          required: true,
          message: "运营地址为必填项",
          trigger: "change",
        },
      ],
    };
    const formDoms = ref<any>({});
    const saveLicense = () => {
      const promiseArr = [];
      const promiseBaseInfo = formDoms.value.baseInfoFormRef.validate();
      promiseArr.push(promiseBaseInfo);
      if (formDoms.value.exInfoFormRefPrivate) {
        const promiseExInfoPrivate =
          formDoms.value.exInfoFormRefPrivate.validate();
        promiseArr.push(promiseExInfoPrivate);
      }
      if (formDoms.value.exInfoFormRefPublic) {
        const promiseExInfoPublic =
          formDoms.value.exInfoFormRefPublic.validate();
        promiseArr.push(promiseExInfoPublic);
      }
      Promise.all(promiseArr).then((res) => {
        const params = {
          retailModificationReqVO: {
            licenseNumber: data.retailInfoRespVO.licenseNumber,
            customerName: data.retailInfoRespVO.customerName,
            engineNo: data.retailInfoRespVO.engineNo,
            issueDate: data.retailInfoRespVO.issueDate,
            licenseSubmitStatus: data.retailInfoRespVO.licenseSubmitStatus,
            regDate: data.retailInfoRespVO.regDate,
            useNatue: data.retailInfoRespVO.useNatue,
            vehicleType: data.retailInfoRespVO.vehicleType,
            vinNo: data.retailInfoRespVO.vinNo,
            dealerCode: data.retailInfoRespVO.dealerCode,
          },
          tengShiReqVO: {
            chargingPoleLocation: data.tengShiRespVO.chargingPoleLocation,
            companyAddress: data.tengShiRespVO.companyAddress,
            contactPerson: data.tengShiRespVO.contactPerson,
            isPrivate: data.tengShiRespVO.isPrivate,
            operationAddress: data.tengShiRespVO.operationAddress,
            operationCompanyAddress: data.tengShiRespVO.operationCompanyAddress,
            phoneNo: data.tengShiRespVO.phoneNo,
            purchaserAddress: data.tengShiRespVO.purchaserAddress,
            registrationAddress: data.tengShiRespVO.registrationAddress,
            representativeName: data.tengShiRespVO.representativeName,
            representativePhone: data.tengShiRespVO.representativePhone,
            storageLocation: data.tengShiRespVO.storageLocation,
            vehicleLicenseFileNo: data.tengShiRespVO.vehicleLicenseFileNo,
          },
        };
        sendEditInfo(params).then((_: any) => {
          (props as any).handleShow();
        });
      });
    };
    const getLicensePicture = async () => {
      const params = {
        vinNo: sessionStorage.getItem("editVlLincese"),
        dealerCode: sessionStorage.getItem("editVlDealerCode"),
        fileName: "RE_" + sessionStorage.getItem("editVlLincese"),
      };
      await getLicenseView({ params }).then((res): any => {
        pictureModal.docType = res.docType;
        pictureModal.pictureModalImg = res.downloadUrl;
        pictureModal.pictureModalTitle = res.docName;
        pictureModal.url = "/rvapi/vl/licenseView";
        pictureModal.base64 = res.base64;
      });
    };
    getLicensePicture();
    const downloadImg = async () => {
      const vinNo = sessionStorage.getItem("editVlLincese");
      await s3Download({
        url: `${
          pictureModal.url
        }?vinNo=${vinNo}&fileName=RE_${vinNo}&dealerCode=${sessionStorage.getItem(
          "editVlDealerCode"
        )}`,
        method: "get",
      });
    };
    const disabledDate = (current: Moment) => {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    };
    const deleteLoad = () => {
      const params = {
        vinNo: sessionStorage.getItem("editVlLincese"),
        dealerCode: sessionStorage.getItem("editVlDealerCode"),
        fileName: "RE_" + sessionStorage.getItem("editVlLincese"),
      };
      // const formData = new FormData();
      // for (const key in params) {
      //   params[key] && formData.append(key, params[key]);
      // }
      basicDelete(params).then((res) => {
        if (res){
          message.error(res)
        } else {
          message.success(`删除成功`);
          pictureModal.pictureModalImg='';
        }
      });
    };
    return {
      data,
      injectObj,
      carModelArray,
      usageArray,
      saveLicense,
      pictureModal,
      downloadImg,
      rules,
      exInfoShow,
      formDoms,
      disabledDate,
      deleteLoad,
    };
  },
});
