
import BasicInfo from '@/views/VehicleLicense/LicenseDetail/BasicInfo.vue';
import OperInfo from '@/views/VehicleLicense/LicenseDetail/OperInfo.vue';
import UpdateInfo from '@/views/VehicleLicense/LicenseDetail/UpdateInfo.vue';
import { defineComponent, ref } from 'vue';
export default defineComponent({
    components: { BasicInfo, OperInfo, UpdateInfo },
    props: {
        handleShow: {
            type: Function,
        },
        detailData: Object,
        canEdit: Boolean,
    },
    setup(props) {
        const activeKey = ref('1');
        const loginShow = () => {
            (props as any).handleShow();
        };
        const licenseDetailShow = ref('basic');
        const tabchange = (event: string) => {
            switch (event) {
                case '2':
                    licenseDetailShow.value = 'oper';
                    break;
                case '3':
                    licenseDetailShow.value = 'update';
                    break;
                default:
                    licenseDetailShow.value = 'basic';
            }
        };
        return {
            loginShow,
            activeKey,
            tabchange,
            licenseDetailShow,
        };
    },
    onBeforeUnmount() {
        sessionStorage.clear();
    },
});
